<template>
  <repair-view-global
    class-name=""
    :repair="estimate.repair"
    :view-header="false"
    :show-code="false"
  >
    <template #buttons>
      <button class="edit" @click="showRepair">
        <span> {{ $t("REPAIRS.SHOW_REPAIR") }} </span>
        <i class="fa-light fa-eye"></i>
      </button>
    </template>
  </repair-view-global>
</template>

<script>
import EstimateRefStandardized from "@/components/EstimateRefStandardized.vue";
import RepairViewGlobal from "../../RepairManagement/partials/RepairViewGlobal.vue";

export default {
  name: "estimate-view-repair",

  components: { RepairViewGlobal, EstimateRefStandardized },

  props: ["estimate"],

  data() {
    return {};
  },

  computed: {
    title: function () {
      return `${this.estimate.code} - ${this.$t("COMMON.REPAIR")}`;
    },
  },

  created() {},

  methods: {
    showRepair() {
      this.$router.push(this.$objectViewRoute(this.estimate.repair));
    },
  },

  mounted() {},

  watch: {},
};
</script>
